import React, {useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import logo from './logo.svg';
import './App.css';
import { read, utils} from 'xlsx';
import xlimg from './xl.png'
import '@aws-amplify/ui-react/styles.css';
import { withAuthenticator, useTheme } from '@aws-amplify/ui-react';
import { listTodos } from './graphql/queries';
import { createTodo as createTodoMutation, deleteTodo as deleteTodoMutation } from './graphql/mutations';
import { API, Storage } from 'aws-amplify';
import styled, { createGlobalStyle } from "styled-components";
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table'
import { matchSorter } from 'match-sorter' 
import awsExports from './aws-exports';
import { Header } from "./Header";
import { SignInHeader } from "./SignInHeader";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


Amplify.configure(awsExports);

const initialFormState = { TrialID: ''}
const Styles = styled.div
`
  padding: 1rem;
  table {
    border-spacing: 0;
    border: 1px solid lightgrey;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid lightgrey;
      border-right: 1px solid lightgrey;

      :last-child {
        border-right: 0;
      }
    }
  }
`

function TestFunc(str){
  console.log(typeof(str))
  // Storage.get(`TU1_F1.xlsm`, { download: true } => {
  //   let blob=new Blob([data.Body], {type: data.ContentType});
  //   let link=document.createElement('a');
  //   link.href=window.URL.createObjectURL(blob);
  //   link.download=url;
  //   link.click();
  // })
  Storage.get(str+'.xlsm', {expires: 60})
  .then(result => window.location.href = result)
  //console.log(xl)
}

const onMouseOver = event => {
  const el = event.target;
  el.style.color = 'blue';
  //el.style.fontWeight = "bold" ;
};
const onMouseOut = event => {
  const el = event.target;
  let black = "#000000";
  el.style.color = 'lightgrey';
  //el.style.fontWeight = "normal" ;
};

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span 
    style={{ color: 'wheat'}}

    >
      Search:{' '}
      <input
        id="inputID"
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}

        style={{
          border: '0',
          background: 'grey',
          margin: 10
        }}
        placeholder={`Search ${count} records...`}
        
      />
    </span>
  )
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      id="inputID"
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        
      }}
      style={{
        
        backgroundColor: 'gray',
        color: 'wheat',
        margin: 10
      }}

      placeholder={`Search ${count} records...`}
       
    />
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
      style={{

        backgroundColor: 'gray',
        color: 'white',
        margin: 10
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={e => {
          setFilter(parseInt(e.target.value, 10))
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <input
        value={filterValue[0] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      to
      <input
        value={filterValue[1] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    })
    ,
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
    
  )

  const {
    getTableProps,
    getTableBodyProps,
    getRowProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter // useGlobalFilter!
  )

  // render first x rows
  const firstPageRows = rows.slice(0, 20)

  return (
    <div>
      <table {...getTableProps()}>
        <thead>
        <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}style={{ color: 'lightgrey',minWidth: column.minWidth, width: column.width }}>
                  {column.render('Header')}
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}

        </thead>
        <tbody {...getTableBodyProps()} >
          {firstPageRows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} style={{ color: 'lightgrey'}}
              //onMouseEnter={event => onMouseOver(event)}
              //onMouseOut={event => onMouseOut(event)}
              >
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}
                  >{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <br />
    </div>
  )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'









const components = {
  Header,
  SignIn: {
    Header: SignInHeader,
  },
};

const resulttext=[];
var data=null;

function App({ isPassedToWithAuthenticator, signOut, user }) {

  const readUploadFile = (e) => {
  e.preventDefault();
  if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
          data = e.target.result;
          const workbook = read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = utils.sheet_to_json(worksheet);
          console.log(workbook.Props);
          var Units = "Metric";

          var TrialNmbr = 0
          var MaxNmbr = 0
          formData.PartNumber = json.filter(function(item){return item.Title == "Part Number";})[0].Value ;
          formData.PartGroup = json.filter(function(item){return item.Title == "Part Group";})[0].Value ;
          formData.Material = json.filter(function(item){return item.Title == "Material";})[0].Value ;
          formData.Coolant = json.filter(function(item){return item.Title == "Coolant";})[0].Value ;
          Units = json.filter(function(item){return item.Title == "Units";})[0].Value ;
          for (let i = 0; i < todo.length; i++) {
            TrialNmbr = parseInt(todo[i].TrialID.replaceAll("TU", ""));
            if (TrialNmbr >= MaxNmbr){
              MaxNmbr=TrialNmbr
            }
          }
          MaxNmbr=MaxNmbr+1;
          formData.TrialID="TU"+MaxNmbr;
          document.getElementById("uppertxt").innerHTML = 
          "Trial ID: " + formData.TrialID + "<br />" +
          "Part Number : " + formData.PartNumber + "<br />" +
          "Part Group : " + formData.PartGroup + "<br />" +
          "Material : " + formData.Material + "<br />" +
          "Coolant : " + formData.Coolant + "<br />" +""
          var subString="";
          var confactor=1;
          console.log(Units);
          if (Units == "Imperial"){
            confactor=24.6
          }
          
          
          const SUMMARYworksheet = workbook.Sheets["Summary"];
          const SUMMARYjson = utils.sheet_to_json(SUMMARYworksheet,{header: ['B', 'ToolName', 'Specification', 'Diameter', 'Width', 'Bore', 'I', 'J','K','L', 'M', 'N', 'O', 'P', 'Q', 'R'], blankrows: false, range:29 });
          console.log(confactor);

          for (let i = 1; i < workbook.SheetNames.length; i++) {
            const sheetName = workbook.SheetNames[i];
            const worksheet = workbook.Sheets[sheetName];
            const json = utils.sheet_to_json(worksheet,{header: ['B', 'C', 'E', 'F', 'G', 'H', 'I', 'J','Title1','Value1', 'M', 'N', 'O', 'P', 'Q', 'R'], blankrows: false });
            console.log(json);
            formData.SbType = sheetName ;
            const jsonnohead = utils.sheet_to_json(worksheet,{header: ['B', 'Tools', 'E', 'F', 'G', 'H', 'I', 'J','K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'], blankrows: false, range:9 });
            const wheels = jsonnohead.filter(row => row.Tools);
            var speclist="";
            var sizelist="";

            
            for (let i = 0; i < workbook.Props.Worksheets-1; i++) {
              if (wheels[i]!= null){
                console.log(wheels[i].Tools);
                speclist = speclist + SUMMARYjson.filter(function(item){return item.ToolName == wheels[i].Tools;})[0].Specification + "\n"
                sizelist = sizelist + (SUMMARYjson.filter(function(item){return item.ToolName == wheels[i].Tools;})[0].Diameter*confactor).toFixed(2) + "x"+
                                      (SUMMARYjson.filter(function(item){return item.ToolName == wheels[i].Tools;})[0].Width*confactor).toFixed(2) + "x"+
                                      (SUMMARYjson.filter(function(item){return item.ToolName == wheels[i].Tools;})[0].Bore*confactor).toFixed(2) +
                                      "\n"
              }

            } 
            formData.WheelSpec= speclist.slice(0, -1);
            formData.WheelSize=sizelist.slice(0, -1);



            
            

            //formData.WheelSize= json.filter(function(item){return item.Title_2 == "Wheel Size";})[0].Value_2 ;
            formData.DressType = json.filter(function(item){return item.Value1 == "Dress Type";})[0].M;
            subString=subString+ "<br />" +
            "Feature : " + formData.SbType + "  ||  " +
            "Dress Type : " + formData.DressType + "  ||  " +
            "Wheel Specs : " + formData.WheelSpec + "  ||  " +
            "Wheel Sizes : " + formData.WheelSize
            document.getElementById("lowertxt").innerHTML = subString
          }
          document.getElementById("fileselectHeader").innerHTML = "Please Check Details"
          document.getElementById("SubmitButton").hidden=false;             
      };
      reader.readAsArrayBuffer(e.target.files[0]);
  }
}

  const { tokens } = useTheme();
  const [todo, setTodo] = useState([]);
  const [formData, setFormData] = useState(initialFormState);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: 'Trial Number',
            accessor: 'TrialID',
            filter: 'fuzzyText',
            Cell: e => <a 
            onMouseEnter={onMouseOver}
            onMouseOut={onMouseOut} 
            onClick={() =>{TestFunc(e.row.values.TrialID)}}
            style={{overflow: "visible",cursor: 'pointer' }}
            >{e.value}
            </a>
              //onMouseEnter={event => onMouseOver(event)}
              //onMouseOut={event => onMouseOut(event)}
          }
        ],
      },
      {
        Header: 'Part',
        columns: [
          {
            Header: 'Part Group',
            accessor: 'PartGroup',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'Feature',
            accessor: 'SbType',
            Filter: SelectColumnFilter,
            filter: 'includes',
            Cell: e => <a 
            onMouseEnter={onMouseOver}
            onMouseOut={onMouseOut} 
            onClick={() =>{TestFunc(e.row.values.TrialID)}}
            style={{ cursor: 'pointer' }} 
            >{e.value}
            </a>
          },
          {
            Header: 'Material',
            accessor: 'Material',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
        ],
      },
      {
        Header: 'Tool',
        columns: [
          {
            Header: 'Specification',
            accessor: 'WheelSpec',
            maxWidth: 100,
            style: {overflow: "visible"},


          },
          {
            Header: 'Size',
            accessor: 'WheelSize',
            filter: 'fuzzyText',
            Cell: e => <a
            style={{color:'blue',overflowwrap: 'break-word'}}
            >{e.value}
            </a>
          },
        ],
      },
      {
        Header: 'Info',
        columns: [
          {
            Header: 'Dress Type',
            accessor: 'DressType',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'Coolant',
            accessor: 'Coolant',
            filter: 'fuzzyText',
          },
        ],
      },
    ],
    []
  )

  //const data = React.useMemo(() => makeData(100000), [])





  useEffect(() => {
    document.title = "Turbines Applications Database";
    fetchTodo();
  }, []);

  async function fetchTodo() {
    const apiData = await API.graphql({ query: listTodos, variables: {limit: 5000}});
    //console.log(apiData);
    const notesFromAPI = apiData.data.listTodos.items;
    await Promise.all(notesFromAPI.map(async todo => {
      return todo;
    }))

    //manipulate data here
    var tempstring = "";
    tempstring = apiData.data.listTodos.items[0].WheelSpec;
    tempstring=tempstring.replace('[', '');
    tempstring=tempstring.replace(']', '');
    tempstring=tempstring.replace('stuff', 'ljknzsadjknbafsdjknbasdjasbnsjb');


    apiData.data.listTodos.items.forEach((el)=>{
      el.WheelSpec = el.WheelSpec.replace('92A', '\n'+'92A');
      el.WheelSpec = el.WheelSpec.replace('P23', 'P23'+'\n');
      //el.WheelSize = el.WheelSize.replace('\n', 'kjbbghjbhjbhjbhjbjhb')
      el.WheelSize = el.WheelSize.replace(/\n/g, '\n');
      
    })


    apiData.data.listTodos.items[0].WheelSpec=tempstring.replace(',', '\n');


    //send to set
    setTodo(apiData.data.listTodos.items);
  }

  async function createTodo() {
    const workbook = read(data, { type: "array" });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const json = utils.sheet_to_json(worksheet);

    for (let i = 1; i < workbook.SheetNames.length; i++) {
      const sheetName = workbook.SheetNames[i];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      formData.SbType = sheetName ;
      console.log(json.filter(function(item){return item[1]}));
      //formData.WheelSpec= json.filter(function(item){return item.Title_2 == "Spec";})[0].Value_2 ;
      //formData.WheelSize= json.filter(function(item){return item.Title_2 == "Wheel Size";})[0].Value_2 ;
      //formData.DressType = json.filter(function(item){return item.Title1 == "Title_2";})[0].Value1 ;
      if (!formData.TrialID) return;
      await API.graphql({ query: createTodoMutation, variables: { input: formData } });
      setTodo([ ...todo, formData ]);
      setFormData(initialFormState);
    }

    await Storage.put(formData.TrialID+".xlsm",data);
    fetchTodo();


  }

  // async function createTodo() {
  //   if (!formData.TrialID) return;
  //   await API.graphql({ query: createTodoMutation, variables: { input: formData } });
  //   setTodo([ ...todo, formData ]);
  //   setFormData(initialFormState);
  // }

  // async function deleteTodo({ id }) {
  //   const newTodoArray = todo.filter(todo => todo.id !== id);
  //   setTodo(newTodoArray);
  //   await API.graphql({ query: deleteTodoMutation, variables: { input: { id } }});
  // }

  

  const [myText, setMyText] = useState("");


  return (
 
    <div className="backdrop">
    <div className="App">
    <div className="btn-group special" role="group" aria-label="..." >
    <label className="Uploadbtn" htmlFor={'upload-button'}
    style={{     
      display: 'flex', justifyContent: 'center',
      }}>           
          <Popup
              trigger={<button className="Uploadbtn" style={{paddingTop:10, paddingBottom:10}}>Upload File</button>}
              modal
              nested
            >
              {close => (
                <div className="modal">
                  <button className="close" onClick={close}>
                    &times;
                  </button>
                  <div className="header" id="fileselectHeader"> Select File </div>
                  <div className="content">

                  <div style={{borderBottom: '1px solid gray'}} id="uppertxt">
                        {myText}
                  </div>
                  <div id="lowertxt">
                        {myText}
                  </div>

                  </div>
                  <div className="actions">
                      <input type="file" onChange={readUploadFile} accept=".xlsm,.xlsx"/>
                    <button
                      id="SubmitButton"
                      hidden={true}
                      className="button"
                      onClick={() => {
                        createTodo();
                        close();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
          </Popup>
      </label>
    </div>
      <div className="box">
      <img style={{marginLeft:30}}src={logo} className="App-logo" alt="logo" />
      <div  style={{ color: 'wheat', marginLeft:30}}> Turbine Applications Database</div>
      </div>
      <Styles>
        <Table className="tabby" columns={columns} data={todo}/>
      </Styles>

    <div className="btn-group special" role="group" aria-label="...">
    <button type="button" className="btn " onClick={signOut} style={{paddingTop:10, paddingBottom:10}}>Sign out</button>
    </div>
    </div>
      {/* <div className="btn-group special" role="group" aria-label="...">
        <input style={{display: 'none'}} id="upload-button" type="file" accept=".xlsm,.xlsx"/>
		  </div> */}
       </div>
  );
}

export default withAuthenticator(App,{
  hideSignUp:true.valueOf,
  components:components,
});


export async function getStaticProps() {
  return {
    props: {
      isPassedToWithAuthenticator: true,
    },
  };
}