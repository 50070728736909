import { Heading, useTheme } from "@aws-amplify/ui-react";

export function SignInHeader() {
  const { tokens } = useTheme();

  return (
    <Heading level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
      <div style={{fontSize: 25,paddingBottom: "10px"}}>
       Turbine Appications Database       
      </div>
      <div style={{fontSize: 20}}>
       Please Sign in      
      </div>

    </Heading>

  );
}