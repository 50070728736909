/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:30aea988-f6e1-4d09-98a7-276d7315cb38",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_xLzWym2Se",
    "aws_user_pools_web_client_id": "22ta8ihpbfnbojnsaklvcn8m9f",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://735zpr4m4zbbxmkvikdzs2cxr4.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-scil7glunfdkzfbvh4hq7ztkhe",
    "aws_user_files_s3_bucket": "turbinesshare02e3ac97a33f48d09f44dcfa00ae57d0125103-staging",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
