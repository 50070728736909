import { Flex, Image, useTheme } from "@aws-amplify/ui-react";
import logo from './logo.svg';
import './Header.css';

export function Header() {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center">
      <Image
        alt="logo"
        src={logo}
        className="Header-logo"
        padding={tokens.space.medium}
      />
    </Flex>
  );
}
