/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      TrialID
      PartNumber
      PartGroup
      SbType
      Material
      Coolant
      DressType
      WheelSpec
      WheelSize
      ToolNumber {
        ToolNumber
        Shape
        Geometery
        Specification
      }
      Stuff
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        TrialID
        PartNumber
        PartGroup
        SbType
        Material
        Coolant
        DressType
        WheelSpec
        WheelSize
        ToolNumber {
          ToolNumber
          Shape
          Geometery
          Specification
        }
        Stuff
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTodos = /* GraphQL */ `
  query SyncTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTodos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        TrialID
        PartNumber
        PartGroup
        SbType
        Material
        Coolant
        DressType
        WheelSpec
        WheelSize
        ToolNumber {
          ToolNumber
          Shape
          Geometery
          Specification
        }
        Stuff
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
